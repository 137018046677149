
.svgContainer {
    position: relative; width: 100%; height: 100%; margin: 0 auto 1em;
    border-radius: 50%;
    pointer-events: none;
    div {
        position: relative; width: 100%; height: 200px; overflow: hidden; border-radius: 50%;
        padding-bottom: 100%;
    }
    .mySVG {
        position: absolute; left: 0; top: 0; width: 100%; height: 100%;
        pointer-events: none;
    }
    &:after {
        content: ""; position: absolute; top: 0; left: 0; z-index: 10; 
        width: inherit; height: inherit; box-sizing: border-box;
        border: solid 2.5px #217093; border-radius: 50%;
    }
}
