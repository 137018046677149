  

  /*Ovveride Navigator border and background:*/
.cytoscape-navigator {
    border: 2px solid #bebebe !important;
    background: white !important;
    height: 25vh !important;
    width: 25vw !important;
    margin-bottom: 24px;
    margin-right: 24px;
    z-index: 150 !important;
  }
  
  /*Add border to View container:*/
  .cytoscape-navigator .cytoscape-navigatorView {
    border: 2px solid #124191;
    background: transparent;
  }
  
  /*Ovveride overlay container when mouse is over Navigator*/
  .cytoscape-navigator:hover .cytoscape-navigatorOverlay {
    border: 2px solid #124191;
    background: transparent;
  }
  
  /*Ovveride view's container when mouse is over view*/
  .cytoscape-navigator.mouseover-view .cytoscape-navigatorView {
    background-color: rgba(18, 65, 145, 0.3);
  }

  #cy {
    width: 100%;
    min-height: 90vw;
    position: relative;
    margin: auto;
    background: #fafafa;
    background-image: radial-gradient(#bebebe 1px, transparent 0);
    background-size: 18px 18px;
  }

  
  